.btn5{
    width: 300rem !important;
    background-color: rgb(144, 117, 84) !important;
    font-size: 20rem !important;
    height: 50rem !important;
    padding: 0 !important;
    border-radius: 10rem !important;
    position: absolute;
    top: 140rem;
    left: 225rem;
    line-height: 50rem;
}

.btn1{
    width: 300rem !important;
    background-color: rgb(144, 117, 84) !important;
    font-size: 20rem !important;
    height: 50rem !important;
    padding: 0 !important;
    border-radius: 10rem !important;
    position: absolute;
    top: 240rem;
    left: 225rem;
    line-height: 50rem;
}

.stop{
    background-color: #f00 !important;
}