.home2 h1{
    margin: 0 !important;
    font-size: 25rem !important;
    color:#fff !important;
}

.home2 h2{
    font-size: 11rem !important;
    color:#fff !important;
    z-index: 999;
    margin: 0 !important;
}




.home2 .title{
    padding-top: 40rem !important;
    padding-bottom: 30rem !important;
}

.home2 .name{
    margin-bottom: 5rem !important;
    overflow: hidden;
    white-space: nowrap;
}

.home2 .company{
    font-size: 5rem !important;
}

.home2 .list{
    display: flex;
    justify-content: space-evenly;
}

