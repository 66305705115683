h1{
    margin: 0 !important;
    font-size: 15rem !important;
    color:#fff !important;
}
h2{
    margin: 0 10rem !important;
    font-size: 6.5rem !important;
    color:#fff !important;
    z-index: 999;
    display: inline-block;
}




.title{
    padding-top: 20rem !important;
    padding-bottom: 10rem !important;
}

.name{
    margin-bottom: 5rem !important;
}
